import { Main_Bathroom as Dark } from '../../../panorama-renderings/dark';
import { Main_Bathroom as Light } from '../../../panorama-renderings/light';

export const Bathroom = {
    name: 'Bathroom',
    level: 'main',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.6368224389024881, longitude: 0.3927742914679558,
    },
    markers: [
        {
            mid: 'lower-bathroom__to__living-2', 
            position: {
                latitude: -0.4305640573714329, longitude: 3.7988884985197653,
            },
            scene: {
                name: 'Living 2',
                positions: {
                    latitude: -0.005413899783750065, longitude: 3.7611897179494194,
                },
            } 
        },
        {
            mid: 'lower-bathroom__to__kitchen', 
            position: {
                latitude: -0.09288050804324666, longitude: 3.900875671083277,
            },
            scene: {
                name: 'Kitchen',
                positions: {
                    latitude: 0.007247768298199286, longitude: 3.6412945588693644,
                },
            } 
        }
    ],
};