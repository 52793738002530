import { Upper_Laundry as Dark } from '../../../panorama-renderings/dark';
import { Upper_Laundry as Light } from '../../../panorama-renderings/light';

export const Laundry = {
    name: 'Upper Laundry',
    level: 'upper',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.5615371304373609, longitude: 5.150128899938596,
    },
    markers: [
        {
            mid: 'upper-laundry__to__upper-hall-2', 
            position: {
                latitude: -0.7842974720115063, longitude: 2.966115463516832,
            },
            scene: {
                name: 'Upper Hall 2',
                positions: {
                    latitude: -0.0187091400411461, longitude: 2.97995346868402,
                },
            } 
        },
        {
            mid: 'upper-laundry__to__upper-bedroom-2', 
            position: {
                latitude: -0.49789476273595223, longitude: 3.3081603812457816,
            },
            scene: {
                name: 'Upper Bedroom 2',
                positions: {
                    latitude: -0.22962233428981138, longitude: 3.1377163590280297,
                },
            } 
        },
        {
            mid: 'upper-laundry__to__upper-bedroom-3', 
            position: {
                latitude: -0.39236853361338286, longitude: 2.647689699076058,
            },
            scene: {
                name: 'Upper Bedroom 3',
                positions: {
                    latitude: -0.37276551772497646, longitude: 2.6734159647043465,
                },
            } 
        }
    ],
};