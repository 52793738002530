import { Main_Hall as Dark } from '../../../panorama-renderings/dark';
import { Main_Hall as Light } from '../../../panorama-renderings/light';

export const Hall = {
    name: 'Hall',
    level: 'main',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.5073713370674158, longitude: 5.3838968510642475,
    },
    markers: [
        {
            mid: 'hall__to__entry', 
            position: {
                latitude: -0.7287870738867022, longitude: 0.0006363206819756521,
            },
            scene: {
                name: 'Entry',
                positions: {
                    latitude: 0.0033302261186851645, longitude: 0.011099060946554851,
                },
            } 
        },
        {
            mid: 'hall__to__lower-hall', 
            position: {
                latitude: -0.7683776119022045, longitude: 3.1318813937011156,
            },
            scene: {
                name: 'Lower Hall',
                positions: {
                    latitude: 0.003886665136395351, longitude: 3.541686811219756,
                },
            } 
        },
        {
            mid: 'hall__to__living-1', 
            position: {
                latitude: -0.30927385730613355, longitude: 4.781614041646488,
            },
            scene: {
                name: 'Living 1',
                positions: {
                    latitude: 0.0002556056662310624, longitude: 4.712738467856752,
                },
            } 
        }
    ],
};