import { Lower_Hall as Dark } from '../../../panorama-renderings/dark';
import { Lower_Hall as Light } from '../../../panorama-renderings/light';

export const Hall = {
    name: 'Lower Hall',
    level: 'lower',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.6018416084668399, longitude: 6.111483010565596,
    },
    markers: [
        {
            mid: 'lower-hall__to__garage', 
            position: {
                latitude: -0.379223396328342, longitude: 6.260803093610604,
            },
            scene: {
                name: 'Garage',
                positions: {
                    latitude: -0.03543196428329365, longitude: 0.034786111085157806
                },
            } 
        },
        {
            mid: 'lower-hall__to__lower-bedroom', 
            position: {
                latitude: -0.5920640601933185, longitude: 4.848453243752421,
            },
            scene: {
                name: 'Lower Bedroom',
                positions: {
                    latitude: -0.0014694689807166483, longitude: 4.75437368659378,
                },
            } 
        },
        {
            mid: 'lower-hall__to__lower-bathroom', 
            position: {
                latitude: -0.32913516586012737, longitude: 4.73412426991227,
            },
            scene: {
                name: 'Lower Bathroom',
                positions: {
                    latitude: -0.004781382617222274, longitude: 4.7313049407216985,
                },
            } 
        },
        {
            mid: 'lower-hall__to__hall', 
            position: {
                latitude: -0.6179187963573498, longitude: 0.7384272125425879,
            },
            scene: {
                name: 'Hall',
                positions: {
                    latitude: -0.40758590880073076, longitude: 5.585093144876038,
                },
            } 
        }
    ],
};