import { Upper_Bedroom3 as Dark } from '../../../panorama-renderings/dark';
import { Upper_Bedroom3 as Light } from '../../../panorama-renderings/light';

export const Bedroom3 = {
    name: 'Upper Bedroom 3',
    level: 'upper',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.36754556653943826, longitude: 2.6829120640898534,
    },
    markers: [
        {
            mid: 'upper-bedroom-3__to__upper-hall-2', 
            position: {
                latitude: -0.6513765702654557, longitude: 5.627289234692199,
            },
            scene: {
                name: 'Upper Hall 2',
                positions: {
                    latitude: -0.010547117656145577, longitude: 0.4663676394094711,
                },
            } 
        },
        {
            mid: 'upper-bedroom-3__to__upper-laundry', 
            position: {
                latitude: -0.3818705482629565, longitude: 5.589032242459307,
            },
            scene: {
                name: 'Upper Laundry',
                positions: {
                    latitude: -0.42298247580104764, longitude: 5.323458954976242,
                },
            } 
        }
    ],
};