import { Upper_Master as Dark } from '../../../panorama-renderings/dark';
import { Upper_Master as Light } from '../../../panorama-renderings/light';

export const Master = {
    name: 'Upper Master',
    level: 'upper',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.2417631685481556, longitude: 5.433967710254766,
    },
    markers: [
        {
            mid: 'upper-master__to__upper-ensuite', 
            position: {
                latitude: -0.7164597813373947, longitude: 1.197100166916694,
            },
            scene: {
                name: 'Upper Ensuite',
                positions: {
                    latitude: -0.30656729033313024, longitude: 1.6686845077263137,
                },
            } 
        },
        {
            mid: 'upper-master__to__upper-hall-1', 
            position: {
                latitude: -0.6662106749370276, longitude: 2.249848329494581,
            },
            scene: {
                name: 'Upper Hall 1',
                positions: {
                    latitude: -0.06542347228308287, longitude: 3.459946066090594,
                },
            } 
        }
    ],
};