import { Lower_Bathroom as Dark } from '../../../panorama-renderings/dark';
import { Lower_Bathroom as Light } from '../../../panorama-renderings/light';

export const Bathroom = {
    name: 'Lower Bathroom',
    level: 'lower',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.5148188407048124, longitude: 5.548574785211769,
    },
    markers: [
        {
            mid: 'lower-bathroom__to__lower-hall', 
            position: {
                latitude: -0.31040896713574995, longitude: 1.581854332028921,
            },
            scene: {
                name: 'Lower Hall',
                positions: {
                    latitude: -0.001297244548130294, longitude: 1.5796794917157215,
                },
            } 
        },
        {
            mid: 'lower-bathroom__to__lower-bedroom', 
            position: {
                latitude: -0.5917428921031886, longitude: 1.4617467234310293,
            },
            scene: {
                name: 'Lower Bedroom',
                positions: {
                    latitude: -0.007808676368139089, longitude: 1.5773273734380253,
                },
            } 
        }
    ],
};