import { Upper_Bathroom as Dark } from '../../../panorama-renderings/dark';
import { Upper_Bathroom as Light } from '../../../panorama-renderings/light';

export const Bathroom = {
    name: 'Upper Bathroom',
    level: 'upper',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.4349355323611168, longitude: 5.653216027563821,
    },
    markers: [
        {
            mid: 'upper-bathroom__to__upper-laundry', 
            position: {
                latitude: -0.6506862092345904, longitude: 1.3776084403853937,
            },
            scene: {
                name: 'Upper Laundry',
                positions: {
                    latitude: -0.009651727516316821, longitude: 1.0155840229722504,
                },
            } 
        },
        {
            mid: 'upper-bathroom__to__upper-hall-2', 
            position: {
                latitude: -0.46699928406875646, longitude: 1.672021011383716,
            },
            scene: {
                name: 'Upper Hall 2',
                positions: {
                    latitude: 0.003160216821384232, longitude: 1.5611907459627754,
                },
            } 
        },
        {
            mid: 'upper-bathroom__to__upper-bedroom-3', 
            position: {
                latitude: -0.3870936850822637, longitude: 1.924890026862468,
            },
            scene: {
                name: 'Upper Bedroom 3',
                positions: {
                    latitude: -0.3308493826304151, longitude: 1.5595557712396164
                },
            } 
        }
    ],
};