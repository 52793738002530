import { Upper_Hall1 as Dark } from '../../../panorama-renderings/dark';
import { Upper_Hall1 as Light } from '../../../panorama-renderings/light';

export const Hall1 = {
    name: 'Upper Hall 1',
    level: 'upper',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.4136161453624334, longitude: 3.4403914790685035,
    },
    markers: [
        {
            mid: 'upper-hall-1__to_upper-hall-2', 
            position: {
                latitude: -0.8566369302981647, longitude: 3.4914841043523523,
            },
            scene: {
                name: 'Upper Hall 2',
                positions: {
                    latitude: -0.0000553047283933239, longitude: 3.0045662845128662,
                },
            } 
        },
        {
            mid: 'upper-hall-1__to__living-1', 
            position: {
                latitude: -0.8404557842776583, longitude: 1.5381208791463055,
            },
            scene: {
                name: 'Living 1',
                positions: {
                    latitude: 0.004785769476298274, longitude: 4.712075776246117,
                },
            } 
        },
        {
            mid: 'upper-hall-1__to__upper-master', 
            position: {
                latitude: -0.43905998754329634, longitude: 5.938047115821568,
            },
            scene: {
                name: 'Upper Master',
                positions: {
                    latitude: -0.21561954036294484, longitude: 5.532380307228727,
                },
            } 
        },
        {
            mid: 'upper-hall-1__to__upper-ensuite', 
            position: {
                latitude: -0.37687974932227997, longitude: 6.2740809875847665,
            },
            scene: {
                name: 'Upper Ensuite',
                positions: {
                    latitude: -0.33700372316941474, longitude: 6.2731774428865945,
                },
            } 
        },
        {
            mid: 'upper-hall-1__to__upper-bedroom-2', 
            position: {
                latitude: -0.4440151848096807, longitude: 3.862756857485891,
            },
            scene: {
                name: 'Upper Bedroom 2',
                positions: {
                    latitude: -0.2541345095480385, longitude: 3.9665432992604597,
                },
            } 
        },
        {
            mid: 'upper-hall-1__to__upper-bedroom-3', 
            position: {
                latitude: -0.4162030782727444, longitude: 3.0430257212294847,
            },
            scene: {
                name: 'Upper Bedroom 3',
                positions: {
                    latitude: -0.2418327281470516, longitude: 2.7326689776042605,
                },
            } 
        }
    ],
};