import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DATA from '../data/panoramaData';

export const fetchPanoramaByName = createAsyncThunk(
  'panorama',
  async (name, thunkAPI) => {
    const response = await DATA.filter((p) => p.name === name);
    return response[0];
  }
);

export const panoramaSlice = createSlice({
  name: 'panorama',
  initialState: {
    data: DATA,
    current: {
      panorama: DATA[6],
      scheme: 'dark',
    },
  },
  reducers: {
    changeScheme: {
      reducer(state, action) {
        state.current.scheme = action.payload.scheme;
      },
      prepare(scheme) {
        return {
          payload: {
            scheme,
          },
        };
      },
    },
  },
  extraReducers: {
    [fetchPanoramaByName.fulfilled]: (state, action) => { 
      console.log('--- fetchPanoramaByName.fulfilled ---');
      console.log('state =', state);
      console.log('action =', action);
      state.current.panorama = action.payload;
    }
  }
});

export const selectDATA = (state) => state.panoramas.data;
export const selectCurrent = (state) => state.panoramas.current;
export const { changeScheme } = panoramaSlice.actions;
export default panoramaSlice.reducer;
