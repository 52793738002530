import React, { useState } from 'react';
import clsx from 'clsx';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import AppBar from '@material-ui/core/AppBar';
import SettingsBackupRestoreTwoToneIcon from '@material-ui/icons/SettingsBackupRestoreTwoTone';
import FullscreenTwoToneIcon from '@material-ui/icons/FullscreenTwoTone';
import SelectAllTwoToneIcon from '@material-ui/icons/SelectAllTwoTone';
import FormatPaintTwoToneIcon from '@material-ui/icons/FormatPaintTwoTone';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Interiors from './Interiors';
// import Description from './Description';

const wciTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: orange[500],
    },
    type: 'dark',
  },
});

const useStyles = makeStyles((theme = wciTheme) => ({
  appBar: {
    pointerEvents: 'none',
    top: 'auto',
    bottom: 0,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  stickToBottom: {
    pointerEvents: 'auto',
    width: '600px',
    margin: '20px auto',
    borderRadius: '5px',
  },
}));

const Controller = (props) => {
  const {
    DATA,
    current,
    selectScheme,
    selectRenderingByName,
    toggleRotate,
    enterFullscreen,
  } = props;

  const classes = useStyles();

  const [dailog, setDailog] = useState(false);

  const [value, setValue] = React.useState('recents');

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 'fullscreen':
        enterFullscreen();
        break;
      case 'rotate':
        toggleRotate();
        break;
      case 'interiors':
        setDailog(true);
        break;
      case 'scheme':
        toggleScheme();
        break;
      default:
        break;
    }
    setValue(newValue);
  };

  const closeDailog = () => {
    setDailog(false);
  };

  const toggleScheme = () => {
    console.log('toggleScheme()');
    current.scheme === 'dark' ? selectScheme('light') : selectScheme('dark');
  };

  const dailogPopWindows = dailog ? (
    <Interiors
        DATA={DATA}
        selectRenderingByName={selectRenderingByName}
        closeDailog={closeDailog}
        selectScheme={selectScheme}
        current={current}
      />
  ) : null;

  return (
    <ThemeProvider theme={wciTheme}>
      <AppBar
        color='transparent'
        position='fixed'
        className={clsx(classes.appBar)}
        elevation={0}
      >
        { /* <Description current={current} /> */}
        <BottomNavigation
          value={value}
          onChange={handleChange}
          showLabels
          className={classes.stickToBottom}
        >
          <BottomNavigationAction
            label='Fullscreen'
            value='fullscreen'
            icon={<FullscreenTwoToneIcon />}
          />
          <BottomNavigationAction
            label='Rotate'
            value='rotate'
            icon={<SettingsBackupRestoreTwoToneIcon />}
          />
          <BottomNavigationAction
            label='Room Selection'
            value='interiors'
            icon={<SelectAllTwoToneIcon />}
          />
          <BottomNavigationAction
            label='Switch Scheme'
            value='scheme'
            icon={<FormatPaintTwoToneIcon />}
          />
        </BottomNavigation>
      </AppBar>

      {dailogPopWindows}
    </ThemeProvider>
  );
};

export default Controller;
