import { Entry as EntryEntry } from './renderings/entry/entry';

import { Bathroom as LowerBathroom } from './renderings/lower/bathroom';
import { Bedroom as LowerBedroom } from './renderings/lower/bedroom';
import { Garage as LowerGarage } from './renderings/lower/garage';
import { Hall as LowerHall } from './renderings/lower/hall';

import { Bathroom as MainBathroom } from './renderings/main/bathroom';
import { Dining as MainDining } from './renderings/main/dining';
import { Family as MainFamily } from './renderings/main/family';
import { Hall as MainHall } from './renderings/hall/hall';
import { Kitchen as MainKitchen } from './renderings/main/kitchen';
import { Living1 as MainLiving1 } from './renderings/main/living1';
import { Living2 as MainLiving2 } from './renderings/main/living2';

import { Hall1 as UpperHall1 } from './renderings/upper/hall1';
import { Hall2 as UpperHall2 } from './renderings/upper/hall2';
import { Bathroom as UpperBathroom } from './renderings/upper/bathroom';
import { Master as UpperMaster } from './renderings/upper/master';
import { Ensuite as UpperEnsuite } from './renderings/upper/ensuite';
import { Laundry as UpperLaundry } from './renderings/upper/laundry';
import { Bedroom2 as UpperBedroom2 } from './renderings/upper/bedroom2';
import { Bedroom3 as UpperBedroom3 } from './renderings/upper/bedroom3';

const DATA = [
  LowerBathroom,
  LowerBedroom,
  LowerGarage,
  LowerHall,
  MainBathroom,
  MainLiving2,
  MainLiving1,
  MainDining,
  MainFamily,
  MainKitchen,
  MainHall,
  EntryEntry,
  UpperEnsuite,
  UpperMaster,
  UpperBedroom3,
  UpperBedroom2,
  UpperBathroom,
  UpperLaundry,
  UpperHall2,
  UpperHall1,
];

export default DATA;
