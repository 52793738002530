import { Main_Kitchen as Dark } from '../../../panorama-renderings/dark';
import { Main_Kitchen as Light } from '../../../panorama-renderings/light';

export const Kitchen = {
    name: 'Kitchen',
    level: 'main',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.29005625950137626, longitude: 2.4945019208515933,
    },
    markers: [
        {
            mid: 'kitchen__to__living-2', 
            position: {
                latitude: -0.5101220490522831, longitude: 0.27426503115958023,
            },
            scene: {
                name: 'Living 2',
                positions: {
                    latitude: -0.009575643159585034, longitude: 0.7096054220456419,
                },
            } 
        },
        {
            mid: 'kitchen__to__bathroom', 
            position: {
                latitude: -0.20343360447286618, longitude: 0.758247776112445,
            },
            scene: {
                name: 'Bathroom',
                positions: {
                    latitude: -0.0018766081344292473, longitude: 0.6904730697626984,
                },
            } 
        },
        {
            mid: 'kitchen__to__hall', 
            position: {
                latitude: -0.32288973330843196, longitude: 1.3393588341896892,
            },
            scene: {
                name: 'Hall',
                positions: {
                    latitude: -0.34742699913004627, longitude: 1.5790194254200602,
                },
            } 
        },
        {
            mid: 'kitchen__to__upper-hall-1', 
            position: {
                latitude: 0.04195905567163161, longitude: 1.5959386206568886,
            },
            scene: {
                name: 'Upper Hall 1',
                positions: {
                    latitude: -0.0029240444292364387, longitude: 4.805845692640153,
                },
            } 
        },
        {
            mid: 'kitchen__to__family', 
            position: {
                latitude: -0.3308219693342176, longitude: 1.9929348280535388,
            },
            scene: {
                name: 'Family',
                positions: {
                    latitude: -0.004144971424272725, longitude: 2.4769211038070327,
                },
            } 
        },
        {
            mid: 'kitchen__to__dining', 
            position: {
                latitude: -0.3481037456710494, longitude: 2.6080590522357943,
            },
            scene: {
                name: 'Dining',
                positions: {
                    latitude: 0.004480174190938602, longitude: 2.736622533481454,
                },
            } 
        }
    ],
};