import { Upper_Ensuite as Dark } from '../../../panorama-renderings/dark';
import { Upper_Ensuite as Light } from '../../../panorama-renderings/light';

export const Ensuite = {
    name: 'Upper Ensuite',
    level: 'upper',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.1579567382781648, longitude: 5.971255869407219,
    },
    markers: [
        {
            mid: 'upper-ensuite__to__upper-master', 
            position: {
                latitude: -0.8677789418443833, longitude: 4.3417565270402845,
            },
            scene: {
                name: 'Upper Master',
                positions: {
                    latitude: -0.162803479831362, longitude: 5.318038388398651,
                },
            } 
        }
    ],
};