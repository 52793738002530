import React from 'react';
import Player from './components/Player';

const App = () => {
  return (
    <div className='App'>
      <Player />
    </div>
  );
};

export default App;
