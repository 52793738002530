import { Entry_Entry as Dark } from '../../../panorama-renderings/dark';
import { Entry_Entry as Light } from '../../../panorama-renderings/light';

export const Entry = {
    name: 'Entry',
    level: 'lower',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.09979186283409991, longitude: 0.01109906094655485,
    },
    markers: [
        {
            mid: 'entry__to__hall', 
            position: {
                latitude: 0.08629125407784266, longitude: 3.135631768421847,
            },
            scene: {
                name: 'Hall',
                positions: {
                    latitude: -0.7100409823895291, longitude: 4.0517954452933385,
                },
            } 
        }
    ],
};