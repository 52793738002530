import { Upper_Bedroom2 as Dark } from '../../../panorama-renderings/dark';
import { Upper_Bedroom2 as Light } from '../../../panorama-renderings/light';

export const Bedroom2 = {
    name: 'Upper Bedroom 2',
    level: 'upper',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.29152404320001013, longitude: 4.175552186302017,
    },
    markers: [
        {
            mid: 'upper-bedroom-2__to__upper-hall-2', 
            position: {
                latitude: -0.5485898153565096, longitude: 0.9991789842948768,
            },
            scene: {
                name: 'Upper Hall 2',
                positions: {
                    latitude: -0.2568414004397508, longitude: 0.567113904426267,
                },
            } 
        },
        {
            mid: 'upper-bedroom-2__to__upper-laundry', 
            position: {
                latitude: -0.5276823839957663, longitude: 0.4233645390787846,
            },
            scene: {
                name: 'Upper Laundry',
                positions: {
                    latitude: -0.5513693499572625, longitude: 1.3796110389542005,
                },
            } 
        },
        {
            mid: 'upper-bedroom-2__to__living-1', 
            position: {
                latitude: -0.3671027434531813, longitude: 0.8053851758155025,
            },
            scene: {
                name: 'Upper Living 1',
                positions: {
                    latitude: -0.009322507061535923, longitude: 4.703776941324151,
                },
            } 
        }
    ],
};