import { Upper_Hall2 as Dark } from '../../../panorama-renderings/dark';
import { Upper_Hall2 as Light } from '../../../panorama-renderings/light';

export const Hall2 = {
    name: 'Upper Hall 2',
    level: 'upper',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.5031994504002828, longitude: 5.111323879669279,
    },
    markers: [
        {
            mid: 'upper-hall-2__to__upper-bathroom', 
            position: {
                latitude: -0.5826605473267579, longitude: 5.013636590797072,
            },
            scene: {
                name: 'Upper Bathroom',
                positions: {
                    latitude: -0.2664799789791412, longitude: 4.675661683973161,
                },
            } 
        },
        {
            mid: 'upper-hall-2__to__upper-laundry', 
            position: {
                latitude: -0.703338739204959, longitude: 6.003275301913485,
            },
            scene: {
                name: 'Upper Laundry',
                positions: {
                    latitude: -0.24321559233620826, longitude: 5.548574785211781,
                },
            } 
        },
        {
            mid: 'upper-hall-2__to__upper-bedroom-2', 
            position: {
                latitude: -0.6876558123791932, longitude: 4.2562424027616235,
            },
            scene: {
                name: 'Upper Bedroom 2',
                positions: {
                    latitude: -0.29481142881204114, longitude: 4.092256069904962,
                },
            } 
        },
        {
            mid: 'upper-hall-2__to__upper-bedroom-3', 
            position: {
                latitude: -0.6016565802897125, longitude: 2.1934889257651506,
            },
            scene: {
                name: 'Upper Bedroom 3',
                positions: {
                    latitude: -0.3162652974623579, longitude: 2.642307605272574,
                },
            } 
        },
        {
            mid: 'upper-hall-2__to__living-1', 
            position: {
                latitude: -0.6196274837206572, longitude: 0.7197132166394226,
            },
            scene: {
                name: 'Living 1',
                positions: {
                    latitude: 0.02667365825430812, longitude: 4.712067940960185,
                },
            } 
        },
        {
            mid: 'upper-hall-2__to__upper-master', 
            position: {
                latitude: -0.3885086220126781, longitude: 0.26176435264054293,
            },
            scene: {
                name: 'Upper Master',
                positions: {
                    latitude: -0.12697030550811084, longitude: 0.24210419467909436,
                },
            } 
        }
    ],
};