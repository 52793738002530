import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const _ = require('lodash');

// const useStyles = makeStyles((theme) => ({
//   themeButtonGroup: {
//     marginTop: '10px',
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// }));

const Interiors = (props) => {
  // const classes = useStyles();
  const { DATA, selectRenderingByName, closeDailog } = props;
  const data = DATA.filter((p) => p.category === 'interiors');

  // const data = _.reverse(d);

  const items = (level) => {
    const list = data.map((item) => { 
      if (item.level === level) {
        return (<Button
          classes={{ root: `wciButton ${item.level}` }}
          size='small'
          key={item.name}
          onClick={() => {
            closeDailog();
            selectRenderingByName(item.name);
          }}
        >
          {_.replace(item.name, /Upper|Main|Lower/gi, '')}
        </Button>);
      } else { 
        return null;
      }
    });
    return list;
  };

  return (
    <Dialog
      classes={{ paper: 'DialogPaper' }}
      open={true}
      onBackdropClick={() => closeDailog()}
      maxWidth='lg'
    >
      <DialogTitle id='Dialog-title' className="DialogTitle">
        Room Selection
      </DialogTitle>
      <Divider />
      <DialogContent classes={{ root: 'wciDialogContent' }}>
        
        <div className="List">
          <div className="Label Upper">
            <h1>UPPER</h1>
          </div>
          <ButtonGroup
            fullWidth={ true}
            orientation='vertical'
            aria-label='vertical contained primary button group'
          >
            {items('upper')}
          </ButtonGroup>
        </div>

        <div className="List">
          <div className="Label Main">
          <h1>MAIN</h1>
          </div>
          <ButtonGroup
            fullWidth={ true}
            orientation='vertical'
            aria-label='vertical contained primary button group'
          >
             {items('main')}
          </ButtonGroup>
        </div>

        <div className="List">
          <div className="Label Lower">
          <h1>LOWER</h1>
          </div>
          <ButtonGroup
            fullWidth={ true}
            orientation='vertical'
            aria-label='vertical contained primary button group'
          >
             {items('lower')}
          </ButtonGroup>
        </div>

        <div className="List">
          <div className="Label Hall" />
          <ButtonGroup
            fullWidth={ true}
            orientation='vertical'
            aria-label='vertical contained primary button group'
          >
             {items('hall')}
          </ButtonGroup>
        </div>

      </DialogContent>
    </Dialog>
  );
};

export default Interiors;
