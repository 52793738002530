import { Lower_Bedroom as Dark } from '../../../panorama-renderings/dark';
import { Lower_Bedroom as Light } from '../../../panorama-renderings/light';

export const Bedroom = {
    name: 'Lower Bedroom',
    level: 'lower',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.15316531747605167, longitude: 6.225005673273229,
    },
    markers: [
        {
            mid: 'lower-bedroom__to__lower-hall', 
            position: {
                latitude: -0.5883815224589966, longitude: 1.607175573090646,
            },
            scene: {
                name: 'Lower Hall',
                positions: {
                    latitude: -0.001298361460723818, longitude: 1.5350713806528054,
                },
            } 
        },
        {
            mid: 'lower-bedroom__to__lower-bathroom', 
            position: {
                latitude: -0.6070717326857631, longitude: 4.712074307192704,
            },
            scene: {
                name: 'Lower Bathroom',
                positions: {
                    latitude: -0.010644963277675945, longitude: 4.732586110610623,
                },
            } 
        },
    ],
};