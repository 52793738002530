import { Main_Family as Dark } from '../../../panorama-renderings/dark';
import { Main_Family as Light } from '../../../panorama-renderings/light';

export const Family = {
    name: 'Family',
    level: 'main',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.17495167429704495, longitude: 2.3283651247350168,
    },
    markers: [
        {
            mid: 'family__to__dining', 
            position: {
                latitude: -0.7265713979673825, longitude: 3.921713734283748,
            },
            scene: {
                name: 'Dining',
                positions: {
                    latitude: -0.012031681065733046, longitude: 4.687380391096494,
                },
            } 
        },
        {
            mid: 'family__to__kitchen', 
            position: {
                latitude: -0.18858276235628213, longitude: 5.0772329587343865,
            },
            scene: {
                name: 'Kitchen',
                positions: {
                    latitude: -0.40344898108564387, longitude: 5.0697084204926295,
                },
            } 
        },
        {
            mid: 'family__to__living-1', 
            position: {
                latitude: -0.6841920236669967, longitude: 5.928352155002821,
            },
            scene: {
                name: 'Living 1',
                positions: {
                    latitude: -0.028244774113581173, longitude: 5.42596179887217,
                },
            } 
        },
        {
            mid: 'family__to__living-2', 
            position: {
                latitude: -0.3042526415406106, longitude: 5.850120875084341,
            },
            scene: {
                name: 'Living 2',
                positions: {
                    latitude: 0.0018809219001769684, longitude: 5.643934185253235,
                },
            } 
        }
    ],
};