export const circleSize = 80;
const speed = '2s';

export const markerCircle = () => {
  return `<svg width='${circleSize}' height='${circleSize}' ><g transform='translate(${
    circleSize / 2
  }, ${circleSize / 2})'><circle cx='0' cy='0' r='${
    (circleSize - 4) / 2
  }' fill='none' stroke='#ffffff' opacity='1'><animateTransform attributeName='transform' type='scale' additive='sum' from='0 0' to='1 1' begin='0s' dur='${speed}' repeatCount='indefinite'></animateTransform><animate attributeName='stroke-width' values='0;4;4;4;0' dur='${speed}' repeatCount='indefinite' /><animate attributeName='opacity' values='0;0.6;0.6;0.6;0' dur='${speed}' repeatCount='indefinite' /></circle></g></svg>`;
};

const createMarker = (marker) => {
  return {
    id: marker.mid,
    longitude: marker.position.longitude,
    latitude: marker.position.latitude,
    html: markerCircle(),
    width: circleSize,
    height: circleSize,
    tooltip: marker.scene.name,
    data: marker.scene.positions,
  }
}

export const markerMaker = (markers) => {
  return {
    markers: markers.map((marker) => createMarker(marker)),
  };
};

export const addMakers = (markers, markersPlugin) => {
  console.log('addMakers()');
  markersPlugin.clearMarkers();
  try {
    markers.map((marker) => {
      markersPlugin.addMarker(createMarker(marker));
      return null;
    });
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.log(error);
    }
  }
};
