import { Lower_Garage as Dark } from '../../../panorama-renderings/dark';
import { Lower_Garage as Light } from '../../../panorama-renderings/light';

export const Garage = {
    name: 'Garage',
    level: 'lower',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.22049425882267748, longitude: 5.366666403511788,
    },
    markers: [
        {
            mid: 'garage__to__lower-hall', 
            position: {
                latitude: -0.4179254692563974, longitude: 2.998013493327866,
            },
            scene: {
                name: 'Lower Hall',
                positions: {
                    latitude: -0.000013161529583083365, longitude: 1.5520495764235827,
                },
            } 
        }
    ],
};