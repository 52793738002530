import { Main_Living2 as Dark } from '../../../panorama-renderings/dark';
import { Main_Living2 as Light } from '../../../panorama-renderings/light';

export const Living2 = {
    name: 'Living 2',
    level: 'main',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.31619988768658835, longitude: 0.003545081093376005,
    },
    markers: [
        {
            mid: 'living-2__to__bathroom', 
            position: {
                latitude: -0.2176221294169336, longitude: 1.0124575302948788,
            },
            scene: {
                name: 'Bathroom',
                positions: {
                    latitude: -0.002309826535514148, longitude: 0.7431604345463542,
                },
            } 
        },
        {
            mid: 'living-2__to__hall', 
            position: {
                latitude: -0.4041392950790448, longitude: 1.929044494213972,
            },
            scene: {
                name: 'Hall',
                positions: {
                    latitude: -0.40559497623642304, longitude: 1.5936100215522888,
                },
            } 
        },
        {
            mid: 'living-2__to__upper-hall-1', 
            position: {
                latitude: -0.3127438572194221, longitude: 2.38190701585986,
            },
            scene: {
                name: 'Upper Hall 1',
                positions: {
                    latitude: 0.007139042511018268, longitude: 4.676249348387646,
                },
            } 
        },
        {
            mid: 'living-2__to__kitchen', 
            position: {
                latitude: -0.4783659185995024, longitude: 3.507514577722267,
            },
            scene: {
                name: 'Kitchen',
                positions: {
                    latitude: -0.19594148604785522, longitude: 3.6111216159265047,
                },
            } 
        },
        {
            mid: 'living-2__to__family', 
            position: {
                latitude: -0.3201605539026109, longitude: 2.5944738857642182,
            },
            scene: {
                name: 'Family',
                positions: {
                    latitude: 0.006196706908174754, longitude: 2.6609590325932193,
                },
            } 
        },
        {
            mid: 'living-2__to__dining', 
            position: {
                latitude: -0.21910358126717622, longitude: 2.884398103789551,
            },
            scene: {
                name: 'Dining',
                positions: {
                    latitude: -0.006698747658282489, longitude: 2.8564787630277544,
                },
            } 
        }
    ],
};