import { Main_Living1 as Dark } from '../../../panorama-renderings/dark';
import { Main_Living1 as Light } from '../../../panorama-renderings/light';

export const Living1 = {
    name: 'Living 1',
    level: 'main',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.2029108322173374, longitude: 5.154309598091083,
    },
    markers: [
        {
            mid: 'living-1__to__living-2', 
            position: {
                latitude: -0.6245336033828885, longitude: 5.410172069792993,
            },
            scene: {
                name: 'Living 2',
                positions: {
                    latitude: -0.003284998715811227, longitude: 5.75415903844762,
                },
            } 
        },
        {
            mid: 'living-1__to__kitchen', 
            position: {
                latitude: -0.21457068620630393, longitude: 4.32011685698082,
            },
            scene: {
                name: 'Kitchen',
                positions: {
                    latitude: -0.0018775251050100472, longitude: 4.554044901270662,
                },
            } 
        },
        {
            mid: 'living-1__to__dining', 
            position: {
                latitude: -0.3435033378071708, longitude: 3.344076100786415,
            },
            scene: {
                name: 'Dining',
                positions: {
                    latitude: -0.029389081331358646, longitude: 3.144559109526862,
                },
            } 
        },
        {
            mid: 'living-1__to__family', 
            position: {
                latitude: -0.5106351452319169, longitude: 3.114185024703126,
            },
            scene: {
                name: 'Family',
                positions: {
                    latitude: -0.0011907068719971825, longitude: 3.1244635334084983,
                },
            } 
        },
        {
            mid: 'living-1__to__upper-hall-1', 
            position: {
                latitude: -0.7348512239840077, longitude: 3.032458151468603,
            },
            scene: {
                name: 'Upper Hall 1',
                positions: {
                    latitude: -0.008731587921063877, longitude: 4.741199355456616,
                },
            } 
        },
        {
            mid: 'living-1__to__hall', 
            position: {
                latitude: -0.7948582602367971, longitude: 1.6879513255041434,
            },
            scene: {
                name: 'Hall',
                positions: {
                    latitude: -0.109078463760651, longitude: 1.58850786521808,
                },
            } 
        }
    ],
};