import LightUpperHall1 from './upper/upper_hall_1.jpeg';
import LightUpperHall2 from './upper/upper_hall_2.jpeg';
import LightUpperLaundry from './upper/upper_laundry.jpeg';
import LightUpperMaster from './upper/upper_master.jpeg';
import LightUpperEnsuite from './upper/upper_ensuite.jpeg';
import LightUpperBedroom2 from './upper/upper_bedroom_2.jpeg';
import LightUpperBedroom3 from './upper/upper_bedroom_3.jpeg';
import LightUpperBathroom from './upper/upper_bathroom.jpeg';

import LightMainBathroom from './main/main_bathroom.jpeg';
import LightMainDining from './main/main_dining.jpeg';
import LightMainFamily from './main/main_family.jpeg';
import LightMainKitchen from './main/main_kitchen.jpeg';
import LightMainLiving1 from './main/main_living_1.jpeg';
import LightMainLiving2 from './main/main_living_2.jpeg';

import LightLowerBedroom from './lower/lower_bedroom.jpeg';
import LightLowerBathroom from './lower/lower_bathroom.jpeg';
import LightLowerGarage from './lower/lower_garage.jpeg';
import LightLowerHall from './lower/lower_hall.jpeg';

import LightHallHall from './hall/hall.jpeg';
import LightEntryEntry from './entry/entry.jpeg';

// ! UPPER
export const Upper_Hall1        = LightUpperHall1;
export const Upper_Hall2        = LightUpperHall2;
export const Upper_Laundry      = LightUpperLaundry;
export const Upper_Master       = LightUpperMaster;
export const Upper_Ensuite      = LightUpperEnsuite;
export const Upper_Bedroom2     = LightUpperBedroom2;
export const Upper_Bedroom3     = LightUpperBedroom3;
export const Upper_Bathroom     = LightUpperBathroom;


// ! MAIN
export const Main_Bathroom      = LightMainBathroom;
export const Main_Dining        = LightMainDining;
export const Main_Family        = LightMainFamily;
export const Main_Kitchen       = LightMainKitchen;
export const Main_Living1       = LightMainLiving1;
export const Main_Living2       = LightMainLiving2;

// ! HALLWAY
export const Main_Hall          = LightHallHall;

// ! LOWER
export const Lower_Bedroom      = LightLowerBedroom;
export const Lower_Bathroom     = LightLowerBathroom;
export const Lower_Garage       = LightLowerGarage;
export const Lower_Hall         = LightLowerHall;

// ! ENTRY
export const Entry_Entry        = LightEntryEntry;