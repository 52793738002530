import { Main_Dining as Dark } from '../../../panorama-renderings/dark';
import { Main_Dining as Light } from '../../../panorama-renderings/light';

export const Dining = {
    name: 'Dining',
    level: 'main',
    category: 'interiors',
    image: {
        light: Light,
        dark: Dark,
    },
    default: {
        latitude: -0.18076136944932486, longitude: 4.712690575470996,
    },
    markers: [
        {
            mid: 'dining__to__kitchen', 
            position: {
                latitude: -0.2366117352957502, longitude: 5.621879140001626,
            },
            scene: {
                name: 'Kitchen',
                positions: {
                    latitude: 0.004408537751324548, longitude: 5.502884528139777,
                },
            } 
        },
        {
            mid: 'dining__to__upper-hall-1', 
            position: {
                latitude: -0.41318956935689655, longitude: 0.3603741971677305,
            },
            scene: {
                name: 'Upper Hall 1',
                positions: {
                    latitude: -0.012468144540830561, longitude: 4.618455471083297,
                },
            } 
        },
        {
            mid: 'dining__to__living-1', 
            position: {
                latitude: -0.32841866136630715, longitude: 0.10222849440691852,
            },
            scene: {
                name: 'Living 1',
                positions: {
                    latitude: -0.004284689604052261, longitude: 6.225572214575175,
                },
            } 
        },
        {
            mid: 'dining__to__living-2', 
            position: {
                latitude: -0.25895358266429014, longitude: 6.274079877845634,
            },
            scene: {
                name: 'Living 2',
                positions: {
                    latitude: -0.017000587344697227, longitude: 6.259324348609332,
                },
            } 
        },
        {
            mid: 'dining__to__bathroom', 
            position: {
                latitude: -0.21604695915022032, longitude: 0.2282405794936369,
            },
            scene: {
                name: 'Bathroom',
                positions: {
                    latitude: 0.0030562154198583436, longitude: 0.8120948582474814,
                },
            } 
        }
    ],
};