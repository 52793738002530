import React from 'react';
import clsx from 'clsx';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import SettingsBackupRestoreTwoToneIcon from '@material-ui/icons/SettingsBackupRestoreTwoTone';
import FullscreenExitTwoToneIcon from '@material-ui/icons/FullscreenExitTwoTone';
import FormatPaintTwoToneIcon from '@material-ui/icons/FormatPaintTwoTone';

const wciTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    type: 'dark',
  },
});

const useStyles = makeStyles((theme = wciTheme) => ({
  appBar: {
    pointerEvents: 'none',
    top: 'auto',
    bottom: 0,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  stickToBottom: {
    pointerEvents: 'auto',
    width: '600px',
    margin: '20px auto',
    borderRadius: '5px',
  },
}));

const FullscreenController = (props) => {
  const { current, selectScheme, toggleRotate, exitFullscreen } = props;
  const currentScheme = current.scheme;
  const classes = useStyles();

  return (
    <ThemeProvider theme={wciTheme}>
      <AppBar
        color='transparent'
        position='fixed'
        className={clsx(classes.appBar)}
        elevation={0}
      >
        {/* <Description current={current} /> */}
        <div className={clsx(classes.stickToBottom)}>
          <ButtonGroup
            classes={{ root: 'wciFullscreenButtonGroup' }}
            fullWidth
            size='small'
            elevation={0}
            variant='contained'
            aria-label='contained primary button group'
          >
            <Button
              classes={{ root: 'wciFullscreenButton' }}
              onClick={() => exitFullscreen()}
              startIcon={<FullscreenExitTwoToneIcon />}
            >
              Exit Fullscreen
            </Button>
            <Button
              classes={{ root: 'wciFullscreenButton' }}
              onClick={() => toggleRotate()}
              startIcon={<SettingsBackupRestoreTwoToneIcon />}
            >
              Rotate
            </Button>
            <Button
              classes={{ root: 'wciFullscreenButton' }}
              onClick={() => currentScheme === 'dark' ? selectScheme('light') : selectScheme('dark') }
              startIcon={<FormatPaintTwoToneIcon />}
            >
            Switch Scheme
            </Button>
          </ButtonGroup>
        </div>
      </AppBar>
    </ThemeProvider>
  );
};

export default FullscreenController;
