/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useSelector, useDispatch } from 'react-redux';
import { Viewer } from 'photo-sphere-viewer';
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers.js';
import 'photo-sphere-viewer/dist/plugins/markers.css';
import Controller from './controller/Controller';
import FullscreenController from './controller/FullscreenController';
import { markerMaker, addMakers } from './Marker';
import {
  selectDATA,
  selectCurrent,
  fetchPanoramaByName,
  changeScheme
} from '../redux/panoramaSlice';

const animationSpeed = '-10rpm';

const Player = () => {
  const dispatch = useDispatch();
  const screen = useFullScreenHandle();
  const [currentViewer, setCurrentViewer] = useState(null);
  const [currentMarkersPlugin, setCurrentMarkersPlugin] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);
  const sphereContainer = useRef(null);
  const DATA = useSelector(selectDATA);
  const current = useSelector(selectCurrent);

  useEffect(() => {
    try {
      const image = current.scheme === 'dark' ? current.panorama.image.dark : current.panorama.image.light;
      currentViewer.setPanorama(image);
    } catch(e) { 
      console.log(e);
    }
  }, [current.scheme])

  useEffect(() => {
    const loadFirstPanorama = () => { 
      /**
       * Initialize Spherical Viewer
       */
      const currentPanorama = current.panorama;
      const viewer = new Viewer({
        container: sphereContainer.current,
        panorama: currentPanorama.image.dark,
        defaultLong: currentPanorama.default.longitude,
        defaultLat: currentPanorama.default.latitude,
        navbar: [],
        defaultZoomLvl: 15,
        mousewheel: true,
        autorotateSpeed: '0.5rpm',
        plugins: [[MarkersPlugin, markerMaker(currentPanorama.markers)]],
        sphereCorrection: {
          pan: 0,
          tilt: -0.05,
          roll: 0 
        }
      });

      /**
       * If it is in development mode, enable console log events
       */
      if (process.env.NODE_ENV === 'development') {
        viewer.on('click', (e, data) => {
          // console.log('click: data =', data);
          // console.log(
          //   `latitude: ${data.latitude}, longitude: ${data.longitude},`
          // );
        });

        viewer.on('position-updated', (e, position) => {
          
          // console.log('position-updated: position =', position);
          // console.log(
          //   `latitude: ${position.latitude}, longitude: ${position.longitude},`
          // );
        });
      }

      /**
       * Store the spherical viewer into component's state CurrentViewer
       */
      setCurrentViewer(viewer);

      /**
       * Marker Event
       * select-marker(event): change panorama image and update all markers
       */
      const markersPlugin = viewer.getPlugin(MarkersPlugin);
      setCurrentMarkersPlugin(markersPlugin);
      markersPlugin.on('select-marker', (e, marker) => {
        const goto = marker.config.tooltip.content;
        markersPlugin.clearMarkers();
        dispatch(fetchPanoramaByName(goto)).then((res) => {
          const scene = res.payload;
          const image = current.scheme === 'dark' ? scene.image.dark : scene.image.light ;
          /**
           * Before set panorama image rotate the viewer to the right position this select marker provided
           */
          viewer.animate({
            longitude: marker.data.longitude,
            latitude: marker.data.latitude,
            speed: animationSpeed,
          });
          /**
           * change the panorama image
           */
          viewer.setPanorama(image).then(() => {
            addMakers(scene.markers, markersPlugin);
          });
        });
      });
    }
    loadFirstPanorama();
  }, []);

  const reportFullscreenChange = useCallback(
    (state, handle) => {
      if (handle === screen) {
        console.log('Screen went to', state, handle);
        setFullscreen(state);
      }
    },
    [screen]
  );

  const toggleRotate = () => {
    currentViewer.toggleAutorotate();
  };

  const selectRenderingByName = (name) => {
    currentMarkersPlugin.clearMarkers();
    dispatch(fetchPanoramaByName(name)).then((res) => {
      const scene = res.payload;
      const image = current.scheme === 'dark' ? scene.image.dark : scene.image.light ;
      /**
       * Before set panorama image rotate the viewer to 
       * the right position this select marker provided
       */
      currentViewer.animate({
        longitude: scene.default.longitude,
        latitude: scene.default.latitude,
        speed: animationSpeed,
      });
      /**
       * change the panorama image
       */
      currentViewer.setPanorama(image).then(() => {
        console.log('scene.markers =>', scene.markers);
        addMakers(scene.markers, currentMarkersPlugin);
      });
    });
  };

  const enterFullscreen = () => {
    screen.enter();
  };

  const exitFullscreen = () => {
    screen.exit();
  };

  const selectScheme = (scheme) => {
    console.log('selectScheme()', scheme);
    dispatch(changeScheme(scheme));
  };

  const fullscreenController = fullscreen ? (
    <FullscreenController
      DATA={DATA}
      current={current}
      selectScheme={selectScheme}
      toggleRotate={toggleRotate}
      exitFullscreen={exitFullscreen}
    />
  ) : null;

  return (
    <>
      <Controller
        DATA={DATA}
        toggleRotate={toggleRotate}
        enterFullscreen={enterFullscreen}
        current={current}
        selectScheme={selectScheme}
        selectRenderingByName={selectRenderingByName}
      />
      <FullScreen handle={screen} onChange={reportFullscreenChange}>
        <div className='Player' ref={sphereContainer}></div>
        {fullscreenController}
      </FullScreen>
    </>
  );
};

export default Player;
