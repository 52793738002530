import DarkUpperHall1 from './upper/upper_hall_1.jpeg';
import DarkUpperHall2 from './upper/upper_hall_2.jpeg';
import DarkUpperLaundry from './upper/upper_laundry.jpeg';
import DarkUpperMaster from './upper/upper_master.jpeg';
import DarkUpperEnsuite from './upper/upper-ensuite.jpeg';
import DarkUpperBedroom2 from './upper/upper_bedroom_2.jpeg';
import DarkUpperBedroom3 from './upper/upper_bedrrom_3.jpeg';
import DarkUpperBathroom from './upper/upper_bathroom.jpeg';

import DarkMainBathroom from './main/main_bathroom.jpeg';
import DarkMainDining from './main/main_dining.jpeg';
import DarkMainFamily from './main/main_family.jpeg';
import DarkMainKitchen from './main/main_kitchen.jpeg';
import DarkMainLiving1 from './main/main_living_1.jpeg';
import DarkMainLiving2 from './main/main_living_2.jpeg';

import DarkLowerBedroom from './lower/lower-bedroom.jpeg';
import DarkLowerBathroom from './lower/lower_bathroom.jpeg';
import DarkLowerGarage from './lower/lower_garage.jpeg';
import DarkLowerHall from './lower/lower_hall.jpeg';

import DarkHallHall from './hall/hall.jpeg';

import DarkEntry from './entry/entry.jpeg';

// ! UPPER
export const Upper_Hall1      = DarkUpperHall1;
export const Upper_Hall2      = DarkUpperHall2;
export const Upper_Laundry       = DarkUpperLaundry;
export const Upper_Master        = DarkUpperMaster;
export const Upper_Ensuite       = DarkUpperEnsuite;
export const Upper_Bedroom2      = DarkUpperBedroom2;
export const Upper_Bedroom3      = DarkUpperBedroom3;
export const Upper_Bathroom      = DarkUpperBathroom;


// ! MAIN
export const Main_Bathroom       = DarkMainBathroom;
export const Main_Dining         = DarkMainDining;
export const Main_Family         = DarkMainFamily;
export const Main_Kitchen        = DarkMainKitchen;
export const Main_Living1        = DarkMainLiving1;
export const Main_Living2        = DarkMainLiving2;

// ! HALLWAY
export const Main_Hall             = DarkHallHall;

// ! LOWER
export const Lower_Bedroom        = DarkLowerBedroom;
export const Lower_Bathroom       = DarkLowerBathroom;
export const Lower_Garage         = DarkLowerGarage;
export const Lower_Hall        = DarkLowerHall;

// ! ENTRY
export const Entry_Entry          = DarkEntry;